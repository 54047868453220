<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <b-container>
        <h2>Test Registrieren</h2>

        <div class="wrapper">
          <label class="editLabel">
            Test Nr.*:
            <input v-model="testNr" type="text" required>
          </label>
          <label class="editLabel">
            Testzentrum*:
            <input v-model="testCenter" type="text" required>
          </label>
          <label class="editLabel">
            Testdatum*:
            <input type="tel" required>
          </label>

          <b-form-select v-model="selected" :options="options"></b-form-select>

          <span style="color: red; margin-top: 16px">
        *Pflichtfeld
        </span>
          <br>
          <button class="saveButton btn" @click="saveTest">
            <h3>
              Speichern
            </h3>
          </button>
          <b-button variant="danger" block @click="$router.push('/tests')">
            <h3>
              Abbrechen
            </h3>
          </b-button>
        </div>

      </b-container>
    </div>

    <b-modal id="modal-2" title="Tan" hide-footer>
      <p class="my-4">Bitte geben Sie die Tan ein</p>
      <label class="editLabel">
        Tan*:
        <input type="text" required>
      </label>
      <b-button class="mt-3" variant="success" block @click="$bvModal.show('modal-3'); $bvModal.hide('modal-2');">Abschicken</b-button>
      <b-button class="mt-3" variant="danger" block @click="$bvModal.hide('modal-2')">Abbrechen</b-button>
    </b-modal>

    <b-modal id="modal-3" title="Danke" hide-footer>
      <p class="my-4">Danke fürs Warnen</p>
      <b-button class="mt-3" variant="success" block @click="savePositiveTest">Bitte</b-button>
    </b-modal>
  </div>
</template>

<script>
import LogoBanner from "../components/LogoBanner";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "TestsNewManuel",
  components: {
    LogoBanner
  },
  data () {
    return {
      selected: null,
      options: [
        { value: false, text: 'Negativ' },
        { value: true, text: 'Positiv' },
      ],
      testNr: null,
      testCenter: null,
    }
  },
  computed: {
    ...mapGetters([
        'getTests'
    ])
  },
  methods: {
    ...mapMutations([
        'addTest'
    ]),
    saveTest() {
      if(this.selected) {
        this.$bvModal.show('modal-2')
      } else {
        let test = {
          id: this.getTests.length + 1,
          date: (new Date()),
          result: 'Negativ',
          testNumber: Math.random(),
          testCenter: "TestCenter2",
        }
        this.addTest(test)
        this.$router.push('/tests')
      }
    },
    savePositiveTest() {
      let test = {
        id: this.getTests.length + 1,
        date: (new Date()),
        result: 'Positiv',
        testNumber: Math.random(),
        testCenter: "TestCenter3",
      }
      this.addTest(test)
      this.$bvModal.hide('modal-3')
      this.$router.push('/tests')
    }
  }
}
</script>

<style scoped>
.content {
  height: 100%;
  max-height: 100%;
}

h1 {
  text-align: center;
  font-weight: bold;
}

.editLabel {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.editLabel input {
  background: #E5E5E5;
  border: 0;
  border-radius: 5px;
  outline: 0;
  height: 40px;
  padding: 4px;
}

.saveButton {
  margin-top: 24px;
  padding: 8px;
  width: 100%;
  background: #21BD64;
  color: white;
  border-radius: 8px;
}
</style>
